import { TableHead as MatTableHead, TableRow as MatTableRow } from '@mui/material';

import { ITableColumn, ITableState } from '/shared/Table/interfaces';

const emptyColumn: ITableColumn = { render: () => null };

export function GenericTableHead<D>(props: ITableState<D>) {
  const {
    classes,
    columns,
    hiddenColumns,
    options,
    masterSelected,
    selectedRowsCount,
    components,
    isMasterSelectionDisabled,
    onMasterSelection,
  } = props;

  const isColumnHidden = (column: ITableColumn<D>) => {
    return hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label);
  };

  const selectionClasses = () => {
    if (isMasterSelectionDisabled) {
      return `${classes?.disabledMasterSelectionWrapper || ''} ${classes.selectionHeadCell || ''}`;
    }

    return classes.selectionHeadCell || '';
  };

  return (
    <MatTableHead className={classes.headerRow}>
      <MatTableRow>
        {options.withSelection && (
          <components.SelectionHeadCell
            {...props}
            className={selectionClasses()}
            initialSelection={masterSelected}
            indeterminate={selectedRowsCount > 0}
            onSelect={onMasterSelection}
          />
        )}
        {columns.map((column: ITableColumn<D>, idx: number) =>
          !isColumnHidden(column) ? <components.HeadCell key={idx} column={column} {...props} /> : null
        )}
        {options.withAdditionalColumn && <components.HeadCell {...props} column={emptyColumn} />}
      </MatTableRow>
    </MatTableHead>
  );
}
