import { FC } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import { ICustomComponent, IPlaceholder } from './IPlaceholder';

const DefaultWrapper: ICustomComponent = ({ children, className }) => <div className={className}>{children}</div>;

const DefaultIcon: ICustomComponent = ({ className }) => <SearchIcon className={className} />;

const PlaceholderComponent: FC<IPlaceholder> = ({
  text = 'No data to display',
  icon: Icon = DefaultIcon,
  wrapper: Wrapper = DefaultWrapper,
  actionButton: ActionButton,
  component: Component,
  className,
}) => {
  const WrapperStyled = styled(Wrapper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px 0',
    color: theme.palette.text.disabled,
  }));

  const IconStyled = styled(typeof Icon === 'string' ? DefaultIcon : Icon)`
    margin-bottom: 20px;
    font-size: 80px;
  `;

  const Text = styled('span')`
    font-size: 16px;
  `;

  const ActionButtonStyled = styled(ActionButton ?? 'span')`
    margin-top: 20px;
  `;

  return Component ? (
    <Component className={className} />
  ) : (
    <WrapperStyled className={className}>
      <IconStyled />
      <Text>{text}</Text>
      {ActionButton && <ActionButtonStyled />}
    </WrapperStyled>
  );
};

export const Placeholder = PlaceholderComponent;
