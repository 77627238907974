import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { TextFilter } from '@playq/octopus-common';

import { snackbarService } from '/common/snackbarService';
import { IBaseSort, IQuery } from '/common/models';
import { kbarToolkit } from '/store';
import { handleEnterPress } from '/helpers';

import { useTableStyles } from '../styles';
import {
  IBaseTableClasses,
  ICollectionTableQueryChange,
  ITableFilterProps,
  ITableState,
  QueryChangeType,
} from '../interfaces';

interface IGenericTableAnyFilterContentProps {
  value: string;
  processing: boolean;
  classes?: IBaseTableClasses;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onClear: () => void;
}

export const GenericTableAnyFilterContent: FC<IGenericTableAnyFilterContentProps> = (props) => {
  const { value, processing, classes: propsClasses, onChange, onSubmit, onClear } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchInputFocused = useSelector(kbarToolkit.selectors.searchInputFocused);
  const dispatch = useDispatch();

  const defaultClasses = useTableStyles();
  const classes = { ...defaultClasses, ...propsClasses };

  const handleKeyPress = handleEnterPress(onSubmit);

  useEffect(() => {
    if (searchInputFocused) {
      inputRef?.current?.focus();
      inputRef?.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });

      dispatch(kbarToolkit.actions.setSearchInputFocused(false));
    }
  }, [dispatch, inputRef, searchInputFocused]);

  return (
    <TextField
      inputRef={inputRef}
      value={value}
      disabled={processing}
      onChange={onChange}
      onBlur={onSubmit}
      onKeyPress={handleKeyPress}
      variant='standard'
      className={classes.toolbarAnyContainer}
      inputProps={{
        'data-testid': 'input-filter',
        className: classes.toolbarAnyInput,
      }}
      InputProps={{
        startAdornment: processing ? (
          <InputAdornment position='start'>
            <CircularProgress className={classes.toolbarSpinner} size={18} />
          </InputAdornment>
        ) : (
          <InputAdornment position='start'>
            <SearchIcon color='disabled' />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment className={classes.toolbarAnyInputEndAdornment} position='end'>
            <IconButton onClick={onClear} data-testid='clear-input-filter' size='large'>
              <ClearIcon color='disabled' fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder='Search...'
    />
  );
};

export function GenericTableAnyFilter<D>(props: ITableState<D>) {
  const { filterBy, onFilterClear, onFilterChange, classes, processing } = props;
  const [value, setValue] = useState(() => {
    if (filterBy.Any !== undefined) {
      return (filterBy.Any as TextFilter).text;
    }
    return '';
  });

  const anyFilterText = filterBy.Any !== undefined ? (filterBy.Any as TextFilter).text : '';
  useEffect(() => {
    setValue(anyFilterText);
  }, [anyFilterText]);

  const handleClear = () => {
    onFilterClear('Any');
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setValue(val);

    if (!val) {
      handleClear();
    }
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    const textFilter = new TextFilter({ text: value });
    if (textFilter.text.startsWith('/')) {
      try {
        new RegExp(textFilter.text.substring(1));
      } catch (e) {
        snackbarService.warning('Wrong Regular Expression');
        throw e;
      }
    }

    onFilterChange('Any', textFilter);
  };

  return (
    <GenericTableAnyFilterContent
      value={value}
      processing={processing}
      onChange={handleFilterChange}
      onSubmit={handleSubmit}
      classes={classes}
      onClear={handleClear}
    />
  );
}

export function CollectionTableAnyFilterDetached<S extends string, SC extends IBaseSort<S>>(
  props: ITableFilterProps<S, SC>
) {
  const { processing, query, setQuery } = props;

  const [value, setValue] = useState<string>('');

  const handleQueryChange = useCallback(
    (qc: ICollectionTableQueryChange<IQuery<S, SC>>) => {
      setQuery(qc.query);
    },
    [setQuery]
  );

  const onChange = useCallback(
    (text: string) => {
      const filterBy = { ...query.filterBy };

      if (!text || text === '') {
        delete filterBy.Any;
        handleQueryChange({ query: { ...query, filterBy }, change: QueryChangeType.FilterClear });
        return;
      }

      const nFilter = new TextFilter();
      nFilter.text = text;
      filterBy.Any = nFilter;

      handleQueryChange({ query: { ...query, filterBy }, change: QueryChangeType.Filter });
    },
    [handleQueryChange, query]
  );

  const anyFilterText = useMemo(
    () => (query.filterBy.Any !== undefined ? (query.filterBy.Any as TextFilter).text : ''),
    [query.filterBy.Any]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    onChange(value);
  };

  const handleClear = () => {
    onChange('');
    setValue('');
  };

  useEffect(() => {
    setValue(anyFilterText);
  }, [anyFilterText]);

  return (
    <GenericTableAnyFilterContent
      value={value}
      processing={processing}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClear={handleClear}
    />
  );
}
