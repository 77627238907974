import { MouseEvent } from 'react';
import { TableCell as MatTableCell } from '@mui/material';
// import { Clear as ClearIcon } from '@mui/icons-material';
import cs from 'classnames';

import { SortDirection } from '@playq/octopus-common';

import { ITableState, ITableColumn, ITableColumnSort, IBaseColumnClasses } from '/shared/Table/interfaces';
import { mergeClasses } from '/helpers';

import { HeaderCell, SortCell, SortLabel, CellLabel /* , SortClearButton */ } from './styles';

export interface ITableHeadCellProps<D> extends ITableState<D> {
  column: ITableColumn<D>;
}

export function GenericTableHeadCell<D>({ column, ...props }: ITableHeadCellProps<D>) {
  const { classes, sortMap, onSortChange, /* onSortClear, */ components, getHiddenClasses } = props;

  const getSortDirection = () => {
    const { sortField } = column;
    if (!sortField || !sortMap.has(sortField.toString())) {
      return undefined;
    }
    const sort = sortMap.get(sortField.toString()) as ITableColumnSort<string>;
    return sort.ord === SortDirection.Ascending ? 'asc' : 'desc';
  };

  const handleSortChange = (event: MouseEvent<HTMLElement>) => {
    onSortChange(event, column.sortField);
  };

  /*
  const handleSortClear = () => {
    onSortClear(column.sortField);
  };
 */

  const columnClasses =
    getHiddenClasses(column.hidden) || mergeClasses<IBaseColumnClasses>(classes, column.classes || {});
  return (
    <MatTableCell
      align={column.align ? column.align : 'inherit'}
      size={column.disablePadding ? 'small' : undefined}
      sortDirection={getSortDirection()}
      className={cs(columnClasses.cell, columnClasses.headerCell)}
    >
      <HeaderCell>
        {!column.sortField ? (
          <CellLabel>{column.label}</CellLabel>
        ) : (
          <SortCell>
            <SortLabel
              active={!sortMap.has(column.sortField.toString())}
              // hideSortIcon={true} // !sortMap.has(column.sortField.toString())
              direction={getSortDirection()}
              onClick={handleSortChange}
            >
              {column.label}
            </SortLabel>
            {/*
            {column.sortField && !!sortMap.has(column.sortField.toString()) && (
              <SortClearButton onClick={handleSortClear} disableRipple={true}>
                <ClearIcon color={'disabled'} fontSize='small' />
              </SortClearButton>
            )}
            */}
          </SortCell>
        )}

        {!!column.filterField && <components.HeadCellFilter column={column} {...props} />}
      </HeaderCell>
    </MatTableCell>
  );
}
