/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment */
import { FC, useCallback, useMemo } from 'react';

import { OffsetLimit } from '@playq/services-shared';

import { entitiesLimitOptions } from '/constants';

import {
  IBaseSortI,
  ITableColumnSort,
  ITableChangeQuery,
  ITableQuery,
  ICollectionTableProps,
  ICollectionTableQuery,
  ITableColumn,
  ICollectionTableColumn,
} from './interfaces';
import { GenericTable } from './Table';
import { getClosestRowPerPageOption } from './helpers/getClosestRowPerPageOption';

const entitiesLimitOptionsCopy = entitiesLimitOptions.slice();

export const CollectionTable = <
  D,
  S extends string,
  F extends string,
  SC extends IBaseSortI<S>,
  TQ extends ICollectionTableQuery<SC>,
>(
  props: ICollectionTableProps<D, S, F, SC, TQ>
) => {
  const {
    data,
    columns,
    sortHelper,
    filterHelper,
    processing,
    initialQuery,
    sortClass: SortClass,
    total,
    rowsPerPageOptions = entitiesLimitOptionsCopy,
    onQueryChange,
    ...restProps
  } = props;

  const tableColumns: ITableColumn<D, S, F>[] = (() => {
    const sortFields: Set<unknown> = new Set(sortHelper.all);
    const filterFields: Set<unknown> = new Set(filterHelper.all);

    return columns.reduce(
      (acc: ITableColumn<D, S, F>[], { queryKey, sort, filter, ...rest }: ICollectionTableColumn<D, S, F>) => {
        const tableColumn: ITableColumn<D, S, F> = rest;

        if (sortFields.has(sort)) {
          tableColumn.sortField = sort;
        } else if (queryKey && sortHelper.isValid(queryKey)) {
          tableColumn.sortField = queryKey as any; // In case queryKey matches the sort key
        }

        if (filterFields.has(filter)) {
          tableColumn.filterField = filter;
        } else if (queryKey && filterHelper.isValid(queryKey)) {
          tableColumn.filterField = queryKey as any; // In case queryKey matches the filter key
        }

        acc.push(tableColumn);
        return acc;
      },
      []
    );
  })();

  const convertCollectionTableQuery = useCallback(
    (collectionTableQuery: TQ | undefined): ITableQuery<S> | undefined => {
      if (!collectionTableQuery) {
        return;
      }

      const { sortBy, iterator, filterBy } = collectionTableQuery;
      const query: ITableQuery<S> = {
        sortBy: sortBy.map((s: SC) => ({
          ord: s.ord,
          field: s.field as unknown as S,
        })),
        filterBy,
        page: Math.max(0, iterator.offset / iterator.limit),
        rowsPerPage: getClosestRowPerPageOption(iterator.limit, rowsPerPageOptions),
      };

      return query;
    },
    [rowsPerPageOptions]
  );

  const initialTableQuery: ITableQuery<S> | undefined = useMemo<ITableQuery<S> | undefined>(
    () => convertCollectionTableQuery(initialQuery),
    [initialQuery, convertCollectionTableQuery]
  );

  const handleQueryChange = ({ query, change }: ITableChangeQuery) => {
    const { rowsPerPage: rPerPage, page, filterBy, sortBy: tableSortBy } = query;

    const iterator = new OffsetLimit({ limit: rPerPage, offset: page * rPerPage });
    const sortBy: SC[] = SortClass ? tableSortBy.map((sb: ITableColumnSort<string>) => new SortClass(sb)) : [];

    const queryResult: TQ = {
      ...(initialQuery as TQ),
      filterBy,
      iterator,
      sortBy,
    };

    onQueryChange({ query: queryResult, change });
  };

  return (
    <GenericTable<D, S, F>
      data={data}
      columns={tableColumns}
      total={total}
      cacheKey={restProps.cacheKey ?? window.location.pathname}
      rowsPerPageOptions={rowsPerPageOptions}
      processing={processing}
      initialQuery={initialTableQuery}
      onQueryChange={handleQueryChange}
      {...restProps}
    />
  );
};

CollectionTable.ofType = <
  D,
  S extends string,
  F extends string,
  SC,
  TQ extends ICollectionTableQuery<SC> = ICollectionTableQuery<SC>,
>() => {
  return CollectionTable as FC<ICollectionTableProps<D, S, F, SC, TQ>>;
};
